<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-layout wrap justify-center>
      <v-flex xs12 pa-0 pa-sm-6>
        <v-card
          class="mx-auto elevation-2 pa-6"
          align="center"
          outlined
        >
      <v-layout wrap class="pb-4">
            <v-flex xs12 md5 lg8 text-md-left text-left>
            <span class="nsbold" style="font-size:18px">Absent Student List</span><br/><span class="nsbold" style="font-size:15px">Session:{{$route.query.name}} ({{formatDate($route.query.date)}})</span>
            </v-flex>
            <v-flex xs12 md3 lg3>
            <v-text-field
              v-model="keyword"
              dense
              @keyup="getData()"
              class="rounded-xl"
              hide-details
              label="Admn.No / Name"
              outlined
              clearable
            ></v-text-field>
          </v-flex>
            <!-- <v-flex xs12 md3 lg1 pt-1 pl-1>
              <v-btn
              @click="getData()"
              style="font-size: 16px; font-family: Nunito Sans, SemiBold"
              color="#766BC0"
              dark
              class="rounded-xl"
              depressed
              
              >SEARCH</v-btn
            >
            </v-flex> -->
          <v-flex xs11 md3 lg1 text-md-right text-left align-self-center>
          <v-btn small plain>
           <v-icon @click="$router.go(-1)" title="Back"
                    style="cursor: pointer;color:#766BC0"
                    >mdi-arrow-left</v-icon
                  >
                  </v-btn></v-flex>
          
        </v-layout>
          <!----------------------filters---------------------------->
          <!-- <v-layout wrap>
            <v-flex xs12 sm3 md3 lg3>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="fromDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromDate"
                    label="From"
                    outlined
                    readonly
                    dense
                    hide-details
                    clearable
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  no-title
                  scrollable
                  @change="$refs.menu.save(fromDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm3 md3 lg3 pl-sm-4 py-2 py-sm-0>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="toDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="toDate"
                    label="To"
                    readonly
                    outlined
                    hide-details
                    dense
                    clearable
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="toDate"
                  no-title
                  scrollable
                  @change="$refs.menu2.save(toDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            
             <v-flex xs12 sm3 md3 lg3 pl-sm-4>
            <v-text-field
              v-model="keyword"
              dense
              hide-details
              label="Search"
              outlined
              clearable
            ></v-text-field>
          </v-flex>
            <v-flex xs12 sm3 md3 lg3 pt-2 text-right>
                      <v-icon @click="$router.go(-1)" title="Back"
                    style="cursor: pointer;color:#766BC0"
                    >mdi-arrow-left</v-icon
                  >
                    </v-flex>
          </v-layout> -->
           
            <!-- <v-flex xs12 md6 lg3 pl-md-4 pt-md-6>
             <v-select
                    :items="issueStatus "
                    v-model="cvalue"
                    label="Status"
                    outlined
                    hide-details
                    dense
                  ></v-select>
          </v-flex> -->
          
            <!--------------------------list------------------------>
          <v-layout wrap justify-center>
            <v-flex xs12 v-if="attendance">
              <v-layout wrap>
                <v-flex xs12 v-if="attendance.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No.</th>
                          <th class="text-left">Admn.No</th>
                          <th class="text-left">Name</th>
                          <th class="text-left">Class</th>
                          <th class="text-left">Division</th>
                          <th class="text-left">Profile</th>
                          <!-- <th class="text-left">View</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr>{{attendance}}</tr>  -->
                        <tr v-for="(item, i) in attendance" :key="i">
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 50 * (page - 1) }}
                            </span>
                          </td>
                          <td>{{ item.admissionNo }}</td>

                          <td><router-link
                              style="text-decoration: none; color: black"
                              :to="'/profile?id=' + item._id"
                            >
                            {{ item.name }}
                            </router-link></td>
                          <td>
                            <span v-if="item.Class">
                              {{ item.Class }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.division">
                              {{ item.division }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          
                          <td>
                            <router-link
                              style="text-decoration: none; color: black"
                              :to="'/profile?id=' + item._id"
                            >
                            <v-icon style="cursor: pointer; color: #766bc0">
                              mdi-eye
                            </v-icon>
                          
                            </router-link>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
                <v-flex v-else xs12 align-self-center text-center pa-2>
                  <span class="nsregular">Currently no item found!</span>
                </v-flex>
              </v-layout>
              <v-pagination
                small
                color="#766BC0"
                total-visible="7"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      page: 1,
      Pagelength: 0,
      menu: false,
      menu2: false,
      fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      keyword: null,
      msg: null,
      //    issueStatus : ["All","Approved", "Out", "Active", "In"],
      cvalue: "All",
      showsnackbar: false,
      ServerError: false,
      appLoading: false,
      attendance: null,
    };
  },
  mounted() {
    if (this.fromDate) {
      var dt = new Date();

      // var day=dt.getDate()
      dt.setDate(dt.getDate() - 30 + 1);
      this.fromDate = dt.toISOString().substr(0, 10);
    }
    if (this.from) {
      var dlt = new Date();

      // var day=dlt.getDate()
      dlt.setDate(dlt.getDate() - 30 + 1);
      this.fromDate = dlt.toISOString().substr(0, 10);
    }
    this.getData();
    // this.testData();

  },
  watch: {
    page() {
      // this.getData();
      this.getData();
    },
    fromDate() {
      if (this.page > 1) this.page = 1;
      this.getData();
    },
    toDate() {
      if (this.page > 1) this.page = 1;

      this.getData();
    },
    // keyword() {
    //   if (this.page > 1) this.page = 1;
    //   // this.getData();
    //   this.getData();
    // },
    cvalue() {
      if (this.page > 1) this.page = 1;
      // this.getData();
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;

      //   let a;
      //   if (this.cvalue == "All") {
      //     a = "";
      //   } else {
      //     a = this.cvalue;
      //   }
      axios({
        method: "post",
        url: "/student/absent/getlist",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          page: this.page,
          limit: 50,
          //   issueStatus : a,
          keyword: this.keyword,
          fromDate: this.fromDate,
          toDate: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.attendance = response.data.absentList;
            this.Pagelength = Math.ceil(response.data.totalLength / 50);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    // testData() {
    //   this.appLoading = true;

    //   //   let a;
    //   //   if (this.cvalue == "All") {
    //   //     a = "";
    //   //   } else {
    //   //     a = this.cvalue;
    //   //   }
    //   axios({
    //     method: "post",
    //     url: "/student/present/absent/getlist",

    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     data: {
    //       id: this.$route.query.id,
    //       page: this.page,
    //       limit: 20,
    //       //   issueStatus : a,
    //       keyword: this.keyword,
    //       fromDate: this.fromDate,
    //       toDate: this.toDate,
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status == true) {
    //         this.attendance = response.data.data;
    //         this.Pagelength = Math.ceil(response.data.totalLength / 20);
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       console.log(err);
    //       this.ServerError = true;
    //     });
    // },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
    formatDate2(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>
